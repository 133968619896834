import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BasePage } from '@ripple/core';
import { IGenFormlyOptions, CustomFieldInModule, WarpEntity } from '@ripple/models';
import { InternalCookieService, WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache, GenericWarpEntityService } from '@ripple/services';
import { AfterSaveEvent, EntityDetailsComponent, BreadcrumbItem } from '@ripple/ui';
import { MenuItem } from 'primeng/api';
import { ClaresLaw, ClaresLawStatus, EntityTypes, ClaresLawSensitiveInformation } from '../../_core/models';
import { ClaresLawService, FormService } from '../../_core/services';
import { environment } from '@ripple/environment';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'sagesse-clares-law-details',
  templateUrl: './clares-law-details.component.html',
  styleUrls: ['./clares-law-details.component.scss']
})
export class ClaresLawDetailsComponent extends BasePage implements OnInit {

  claresLaw: ClaresLaw;

  claresLawSensitiveInformation: ClaresLawSensitiveInformation;

  breadcrumbs:BreadcrumbItem[] = [
    { name: "Clare's Law",
      url: "/claresLaw"
    }];

  fieldOrders = [
    'clareslawcanleavevoicemail',
    'receiveddate',
    'datereferred',
    'datecompleted',
    // 'organization',
    // 'organizationcontact',
    'referencenumber',
    'birth date',
    'gender',
    'phrase',
    'city',
    'community',
    'contacttime',
    'interpreterrequired',
    'currentlyreceivingsupports',
    'ifcontinuereceivingsupport',
    'pastorcurrentrelationship',
    'currentlyresidewithpod',
    'children',
    'clareslawreferraltype',
    'levelofconcern',
    'notes',
    'simplewysiwigdescription'
  ];
  generatorOptions: IGenFormlyOptions = {
    hiddenCondition: (cfim: CustomFieldInModule) => {
      if (cfim.key.toLowerCase() === 'datereferred')
        return (model) =>
          !(model.clareslawstatus &&
            model.clareslawstatus.length > 0 &&
            model.clareslawstatus[0].id !== ClaresLawStatus.NEW);

      if (cfim.key.toLowerCase() === 'datecompleted')
        return (model) =>
          !(model.clareslawstatus &&
          model.clareslawstatus.length > 0 &&
          model.clareslawstatus[0].id === ClaresLawStatus.COMPLETE);

      if (cfim.key.toLowerCase() === 'ifcontinuereceivingsupport')
      return (model) =>
        !(model.currentlyreceivingsupports &&
        model.currentlyreceivingsupports.length > 0 &&
        model.currentlyreceivingsupports[0].id === 333);

      return !this.fieldOrders.includes(cfim.unchangeableName.toLowerCase());
    },
    styles: {
      'receiveddate': 'p-sm-12 p-md-12 ui-fluid',
      'datereferred': 'p-sm-12 p-md-12 ui-fluid',
      'datecompleted': 'p-sm-12 p-md-12 ui-fluid',
      'referencenumber': 'p-sm-12 p-md-12 ui-fluid',
      'first name': 'p-sm-12 p-md-12 ui-fluid',
      'last name': 'p-sm-12 p-md-12 ui-fluid',
      'birth date': 'p-sm-12 p-md-12 ui-fluid',
      'gender': 'p-sm-12 p-md-12 ui-fluid',
      'phrase': 'p-sm-12 p-md-12 ui-fluid',
      'city': 'p-sm-12 p-md-12 ui-fluid',
      'community': 'p-sm-12 p-md-12 ui-fluid',
      'contactinformation': 'p-sm-12 p-md-12 ui-fluid',
      'clareslawcanleavevoicemail': 'p-sm-12 p-md-12 ui-fluid',
      'contacttime': 'p-sm-12 p-md-12 ui-fluid',
      'interpreterrequired': 'p-sm-12 p-md-12 ui-fluid',
      'currentlyreceivingsupports': 'p-sm-12 p-md-12 ui-fluid',
      'ifcontinuereceivingsupport': 'p-sm-12 p-md-12 ui-fluid',
      'pastorcurrentrelationship': 'p-sm-12 p-md-12 ui-fluid',
      'currentlyresidewithpod': 'p-sm-12 p-md-12 ui-fluid',
      'organization': 'p-sm-12 p-md-12 ui-fluid',
      'organizationcontact': 'p-sm-12 p-md-12 ui-fluid',
      'children': 'p-sm-12 p-md-12 ui-fluid',
      'levelofconcern': 'p-sm-12 p-md-12 ui-fluid',
      'clareslawreferraltype': 'p-sm-12 p-md-12 ui-fluid',
      'notes': 'p-sm-12 p-md-12 ui-fluid'
    },
    expressionProperties: (cfim) => {
      if (cfim.key.toLowerCase() === 'interpreterrequired' || cfim.key.toLowerCase() === 'currentlyreceivingsupports') {
        const allOptions = cfim.cf_choices;
        return {
          'templateOptions.options': (model, formState, field?) => {
            return allOptions.map(o => {
              if (o.id === 333) o['hasSpecifyText'] = true;
              return o;
            });
          },
          'templateOptions.optionalClass':(model, formState, field?) => {
            return 'p-col-12 p-md-6';
          },
        };
      } else if (cfim.key.toLowerCase() === 'community') {
        return {
          'templateOptions.options': (model, formState, field?) => {
            const allOptions = field.templateOptions.actualOptions;
            if (allOptions &&
              this.claresLaw.properties.community &&
              this.claresLaw.properties.community.length > 0 &&
              this.claresLaw.properties.community[0].id > 0 &&
              !this.claresLaw.properties.community[0].name) {
                const option = allOptions.find(o => o.id === this.claresLaw.properties.community[0].id);
                if (option) this.claresLaw.properties.community = [option];
                this.claresLaw.properties = { ...this.claresLaw.properties };
            }
            return allOptions;
          }
        };
      }
       else
        return {
          'templateOptions.optionalClass':(model, formState, field?) => {
            if (field.type === 'radio-multiple-col') return 'p-col-12 p-md-6';
            return '';
          },
        };
    },
    onChange: (cfim: CustomFieldInModule) => {
      if (cfim.key.toLowerCase() === 'city') {
        return ((field: FormlyFieldConfig, event?: any) => {
          console.log('model', event.model);
          // auto fill closest community
          if (event.model.city) {
            const communityLabel = document.querySelector('label[for*="_select-entity_community_"]');
            if (communityLabel) communityLabel.innerHTML = 'Community <i class="pi pi-spinner pi-spin"></i>';
            this.claresLawService.getClosestCommunity(event.model.city).subscribe(communityId => {
              if (communityLabel) communityLabel.innerHTML = 'Community';
              if (communityId > 0)
                this.claresLaw.cfcProperty('community', communityId);
                this.claresLaw.properties = { ...this.claresLaw.properties };
            });
          } else {
            this.claresLaw.properties.community = [];
            this.claresLaw.properties = { ...this.claresLaw.properties };
          }
        });
      } else {
        return ((field: FormlyFieldConfig, event?: any) => {
          console.log('model', event.model);
        });
      }
    }
  };

  // sensitive info form
  sensitiveInfoFieldOrders =  [
    'first name',
    'last name',
    'contactinformation'
  ];

  sensitiveInfoGeneratorOptions: IGenFormlyOptions = {
    hiddenCondition: (cfim: CustomFieldInModule) => {
      return !this.sensitiveInfoFieldOrders.includes(cfim.unchangeableName.toLowerCase());
    },
    styles: {
      'first name': 'p-sm-12 p-md-12 ui-fluid',
      'last name': 'p-sm-12 p-md-12 ui-fluid',
      'contactinformation': 'p-sm-12 p-md-12 ui-fluid',
    }
  };

  statusMenuItems: MenuItem[];
  activeIndex: number = 1;
  ClaresLawStatus = ClaresLawStatus;

  // form
  linkedForm: WarpEntity;
  formData: WarpEntity;

  tabIndex: number = 0;

  get showReferralButton() {
    return this.claresLaw && this.claresLaw.entityId > 0 &&
      this.claresLaw.clareslawstatus && this.claresLaw.clareslawstatus.id === ClaresLawStatus.NEW;
  }

  get showUnreferButton() {
    return this.claresLaw && this.claresLaw.entityId > 0 &&
      this.claresLaw.clareslawstatus && this.claresLaw.clareslawstatus.id === ClaresLawStatus.REFERRED;
  }

  @ViewChild("ed") entityDetails: EntityDetailsComponent;

  claresLawService: ClaresLawService;

  // custom saving buttons
  splitButtons: MenuItem[];
  saving = false;

  constructor(
    private logService: WarpEntityLogService,
    private serviceFactory: WarpEntityCacheFactoryService,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: InternalCookieService,
    private formService: FormService,
    private entityService: GenericWarpEntityService,
  ) {
    super(logService);
    this.claresLawService = this.serviceFactory.get(EntityTypes.ClaresLaw) as ClaresLawService;

  }

  ngOnInit(): void {

    this.route.params.subscribe((params) => {
      this.claresLawService.getCfimOptions('clareslawstatus').subscribe(opts => {
        this.statusMenuItems = opts.map(opt => ({label: opt.optionName}));
        const id = parseInt(params.entityId, 10);
        if (!isNaN(id)) {
          this.switchSplitButtons('update');
          this.sub = this.claresLawService.get(id).subscribe((claresLaw) => {
            this.claresLaw = claresLaw;
            if (this.claresLaw.sensitiveInfoEntity) {
              this.claresLawSensitiveInformation = new ClaresLawSensitiveInformation(this.claresLaw.sensitiveInfoEntity);
            }
            this.breadcrumbs = [
              { name: "Clare's Law", url: "/claresLaw" },
              { name: this.claresLaw?.referencenumber }
            ];

            this.activeIndex = this.statusMenuItems.findIndex(mi => mi.label === this.claresLaw.clareslawstatus.optionName);
            this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
            if (this.claresLaw.forms && this.claresLaw.forms.length > 0)
              this.linkedForm = new WarpEntity(this.claresLaw.forms[0]);
            if (this.claresLaw.formDatas && this.claresLaw.formDatas.length > 0)
              this.formData = new WarpEntity(this.claresLaw.formDatas[0]);
          });
        } else {
          this.switchSplitButtons('add');
          this.claresLawSensitiveInformation = ClaresLawSensitiveInformation.empty();
          this.claresLaw = ClaresLaw.empty()
            .cfcProperty('clareslawstatus', ClaresLawStatus.NEW)
            .linkedProperty('clareslawsensitiveinformation', this.claresLawSensitiveInformation);
          this.activeIndex = 0;
          this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
          this.linkedForm = null;
          this.formData = null;
        }

      });
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string): string {
    return '';
  }

  afterSave(e: AfterSaveEvent) {
    if (e.action === 'add') this.router.navigate([`../${e.entity.entityId}`], { relativeTo: this.route });
    else if (e.action === 'delete') {
      const queryParams = {};
      queryParams['msg'] = `Clare's Law Deleted Successfully!`;
      this.router.navigate(['../'], { relativeTo: this.route, queryParams });
    }
  }

  back() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  refer() {
    const queryParams = {
      tags: this.claresLaw.properties.organizationtags ?
        this.claresLaw.properties.organizationtags.map(tag => tag.id).join(',') : '',
      community: this.claresLaw.community ? this.claresLaw.community.id : '',
      teams: environment['claresLawTeamId'],
      ifSortByDistance: this.claresLaw.community ? '1' : '0',
      ifSortByTagsMatches: this.claresLaw.properties.organizationtags && !this.claresLaw.community ? '1' : '0'
    };
    this.cookieService.setCookie('referenceNumber', this.claresLaw.referencenumber);
    this.cookieService.setCookie('clareLawID', this.claresLaw.entityId.toString());
    this.router.navigate(['/organization'], { relativeTo: this.route, queryParams });
  }

  unRefer() {
    this.formService.removeToken(this.claresLaw.handofftoken).toPromise().then(result => {
      this.claresLaw
        .cfcProperty('clareslawstatus', ClaresLawStatus.NEW)
        .property('handofftoken', '');
      this.activeIndex = 0;
      this.entityDetails.onButtonPress('edit');
    });

  }

  goToOriginal() {
    if (this.claresLaw.originalclareslaw.id)
      this.router.navigate([`../${this.claresLaw.originalclareslaw.id}`], { relativeTo: this.route });
  }

  // custom save button
  switchSplitButtons(key) {
    switch (key) {
      case 'add':
        this.splitButtons = [
          {
            label: 'Add',
            icon: this.saving ? 'pi pi-spinner pi-spin' : 'pi pi-plus',
            disabled: this.saving,
            command: () => this.save(),
          },
        ];
        break;
      case 'update':
        this.splitButtons = [
          {
            label: 'Save',
            icon: this.saving ? 'pi pi-spinner pi-spin' : 'pi pi-check',
            disabled:
              this.saving,
            command: () => this.save(),
            items: [{ label: 'Delete', icon: 'pi pi-trash', disabled: this.saving, command: () => this.delete() }],
          },
        ];
        break;
    }
  }

  updateButtonState() {
    if (!this.claresLaw) return;

    if (this.claresLaw.entityId < 0) {
      this.switchSplitButtons('add');
    } else {
      this.switchSplitButtons('update');
    }
  }

  setSavingProcess(saving: boolean) {
    this.saving = saving;
    this.updateButtonState();
  }

  save() {
    this.setSavingProcess(true);
    forkJoin([
      this.entityService.getEntityStructure(this.claresLaw.entityTypeId).pipe(first()),
      this.entityService.getEntityStructure(this.claresLawSensitiveInformation.entityTypeId).pipe(first()),
    ])
      .toPromise()
      .then(([claresLawType, claresLawSensitiveInformationType]) => {
        this.entityService
          .syncWithChildren({ type: claresLawType, entity: this.claresLaw }, { type: claresLawSensitiveInformationType, entity: this.claresLawSensitiveInformation })
          .subscribe((entities) => {
            this.setSavingProcess(false);
            this.afterSave({
              action: this.claresLaw.entityId < 0 ? 'add' : 'save',
              entity: entities[0].entityTypeId === EntityTypes.ClaresLaw ? entities[0] : entities[1],
            });
          });
      });
  }

  delete() {
    this.setSavingProcess(true);
    this.entityService.delete([this.claresLaw, this.claresLawSensitiveInformation]).then((entities) => {
      this.setSavingProcess(false);
      this.afterSave({
        action: 'delete',
        entity: null//entities[0].entityTypeId === EntityTypes.ClaresLaw ? entities[0] : entities[1],
      });
    });
  }

  noteClick(event) {
    console.log(event, this.tabIndex);
    this.tabIndex = 1;
  }

}
